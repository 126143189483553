<template>
  <div id="statistics bg-light">
    <h1>统计</h1>
    <el-row>
      <el-col :sm="24" :md="12">
        <el-card class="dataBox">
          <div id="chart1" style="width: 100%;height: 300px"></div>
        </el-card>
      </el-col>
      <el-col :sm="24" :md="12">
        <el-card class="dataBox" style="justify-content: center ;height: 340px">
          <h3>任务进度</h3>
          <el-progress :text-inside="true" :stroke-width="26" :percentage="70"></el-progress>
          <el-progress :text-inside="true" :stroke-width="24" :percentage="100" status="success"></el-progress>
          <el-progress :text-inside="true" :stroke-width="22" :percentage="80" status="warning"></el-progress>
          <el-progress :text-inside="true" :stroke-width="20" :percentage="50" status="exception"></el-progress>
        </el-card>
      </el-col>
      <el-col :sm="24" :md="24">
        <el-card class="dataBox">
          <h3>任务完成情况</h3>
          <el-row>
            <el-col :sm="24" :md="8">
              <div id="chart2" style="width: 100%;height: 300px"></div>
            </el-col>
            <el-col :sm="24" :md="16">
              <el-table
                :data="taskSubmission.taskSubmissionList"
                style="width: 100%"
                :row-class-name="checkTaskSubmission">
                <el-table-column
                  label="日期"
                  width="200">
                  <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.gmtModified }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="任务"
                  width="160">
                </el-table-column>
                <el-table-column
                  label="任务进度"
                  width="160">
                  <tamplate slot-scope="scope">
                    <el-tag v-if="scope.row.status==='normal' " type="success"><i class="el-icon-loading"></i> 进行中
                    </el-tag>
                    <el-tag v-else-if="scope.row.status==='mutual'" type="warning"><i class="el-icon-loading"></i> 互评中
                    </el-tag>
                    <el-tag v-else-if="scope.row.status==='completed'" type="info"><i class="el-icon-check"></i> 已结束
                    </el-tag>
                  </tamplate>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="完成情况">
                  <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <el-tag v-if="scope.row.myTaskSubmission===null" size="medium">未完成</el-tag>
                      <el-tag v-else-if="scope.row.myTaskSubmission!==null&&scope.row.myTaskSubmission.score!==null"
                              size="medium">{{ scope.row.myTaskSubmission.score }}分
                      </el-tag>
                      <el-tag v-else-if="scope.row.myTaskSubmission!==null&&scope.row.myTaskSubmission.score===null"
                              size="medium" type="info">已完成
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :sm="24" :md="12">
        <el-card class="dataBox"></el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Statistics',
  data () {
    return {
      termId: 0,
      record: {
        finished: 0,
        unfinished: 0,
        recordList: []
      },
      taskSubmission: {
        taskSubmissionList: []
      },
      studentId: 0
    }
  },
  mounted () {
    this.termId = this.$route.params.termId
    this.checkLogin().then(res => {
      this.studentId = res.data.data.id
      this.getRecord()
      this.getDiscussion()
      this.getTaskSubmission()
    })
  },
  methods: {
    checkLogin () {
      return axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/student/checkLogin'
      })
    },
    initChart2 () {
      const chart2 = this.$echarts.init(document.getElementById('chart2'))
      const scoreList = []
      const taskNameList = []
      const allTask = this.taskSubmission.taskSubmissionList
      for (let i = 0; i < allTask.length; i++) {
        if (allTask[i].myTaskSubmission !== null && allTask[i].myTaskSubmission.score !== null) {
          console.log(allTask[i].myTaskSubmission)
          scoreList.push(allTask[i].myTaskSubmission.score)
          taskNameList.push(allTask[i].title)
        }
      }
      console.log(scoreList)
      chart2.setOption({
        title: {
          text: '学习章节学习情况',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        xAxis: {
          type: 'category',
          data: taskNameList
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: scoreList,
            type: 'line'
          }
        ]
      })
    },
    initChart1 () {
      const chart1 = this.$echarts.init(document.getElementById('chart1'))
      chart1.setOption({
        title: {
          text: '学习章节学习情况',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              {
                value: this.record.finished,
                name: '已完成'
              },
              {
                value: this.record.unfinished,
                name: '未完成'
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    },
    getRecord () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/record/showMyRecordsByTermId',
        params: {
          termId: this.termId
        }
      }).then(res => {
        if (res.data.status) {
          const record = res.data.data
          this.record.recordList = record
          for (let i = 0; i < record.length; i++) {
            if (record[i].finished) {
              this.record.finished += 1
            } else {
              this.record.unfinished++
            }
          }
          this.initChart1()
        }
      })
    },
    getDiscussion () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/comment/countCommentsByTermIdAndStudentId',
        params: {
          termId: this.termId,
          studentId: this.studentId
        }
      }).then(res => {
        if (res.data.status) {
          console.log(res)
        }
      })
    },
    getTaskSubmission () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/showTasksWithMyTaskSubmissionByTermId',
        params: {
          termId: this.termId
        }
      }).then(res => {
        if (res.data.status) {
          this.taskSubmission.taskSubmissionList = res.data.data
          const scoreList = []
          const taskNameList = []
          const allTask = this.taskSubmission.taskSubmissionList
          for (let i = 0; i < allTask.length; i++) {
            if (allTask[i].myTaskSubmission !== null) {
              const mySubmission = allTask[i].myTaskSubmission
              console.log(mySubmission.score)
              if (mySubmission.score !== null) {
                scoreList.push(allTask[i].myTaskSubmission.score)
                taskNameList.push(allTask[i].title)
              }
            }
          }
          this.initChart2()
        }
      })
    },
    checkTaskSubmission ({
      row,
      rowIndex
    }) {
      if (rowIndex === 1) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    }
  }
}
</script>

<style scoped>
#statistics {
  min-height: 100vh;
  padding: 150px 0;
}

.el-progress {
  margin: 20px 0;
}

.dataBox {
  margin: 20px;
}
</style>
